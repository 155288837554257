// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationTR from './locales/tr/translation.json';
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

// Initialize i18next
i18n.use(initReactI18next).init({
    resources: {
        tr: {
            translation: translationTR,
        },
        en: {
            translation: translationEN,
        },
        de: {
            translation: translationDE,
        },
    },
    lng: 'tr', // Default language
    fallbackLng: 'tr', // If translation is not found, use Turkish
    interpolation: {
        escapeValue: false, // React already escapes values
    },
});

export default i18n;

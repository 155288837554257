import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';  // Correct
import './Dashboard.css';
import logo from '../logo_light.png';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTachometerAlt, faFileAlt, faPlus, faSignOut, faEdit} from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
    const [cvs, setCvs] = useState([]);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token'); // Remove the token from localStorage
        navigate('/'); // Redirect to the landing page or login page
    };

    useEffect(() => {
        const fetchCvs = async () => {
            if (token) {
                try {
                    // Decode the JWT token to get user ID
                    const decodedToken = jwtDecode(token);
                    const userId = decodedToken.id;

                    // Make a GET request to fetch the user's CVs
                    const {data} = await axios.get(`https://backend.cvblok.com/cvs/list/${userId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`  // Include the JWT token in the Authorization header
                        }
                    });

                    // Set the retrieved CVs in the state
                    setCvs(data);
                } catch (error) {
                    console.error('Error fetching CVs:', error);
                    alert('An error occurred while fetching CVs');
                }
            } else {
                navigate('/');  // Redirect to login page if the token is missing
            }
        };

        fetchCvs();
    }, [token, navigate]);  // Fetch CVs whenever the token changes


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('de-DE', {year: 'numeric', month: 'long', day: 'numeric'});
    };

    return (
        <div className="dashboard-wrapper">
            <aside className="sidebar">
                <div className="logo">
                    <img src={logo} alt="Logo" className="logo-image"/>
                </div>

                <Link to="/editor" className="new-cv-btn">
                    <FontAwesomeIcon icon={faPlus} className="menu-icon"/>
                    <span className="menu-text">Neu</span>
                </Link>

                <nav className="menu">
                    <ul>
                        <li className="menu-item">
                            <Link to="/dashboard">
                                <FontAwesomeIcon icon={faTachometerAlt} className="menu-icon"/>
                                <span className="menu-text">Dashboard</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/cvs">
                                <FontAwesomeIcon icon={faFileAlt} className="menu-icon"/>
                                <span className="menu-text">Lebensläufe</span>
                            </Link>
                        </li>
                    </ul>
                </nav>

                <Link className="logout-btn" onClick={handleLogout} to="#">
                    <FontAwesomeIcon icon={faSignOut} className="menu-icon"/>
                    <span className="menu-text">Abmelden</span>
                </Link>
            </aside>

            {/* Main Content */}
            <div className="dashboard-container">
                <header className="dashboard-header">
                    <h1>Lebensläufe</h1>
                </header>

                <div className="resume-grid">
                    <Link to="/editor" className="resume-card new-cv">
                        <p>Neuen Lebenslauf erstellen</p>
                        <span>+</span>
                    </Link>
                    {cvs.map((cv) => (
                        <div key={cv.id} className="resume-card">
                            {/* Preview Content */}
                            {cv.personalData && (
                                <div className="cv-preview">
                                    {/* Image with Placeholder */}
                                    <div className="cv-preview-img-wrapper">
                                        {cv.personalData.photo ? (
                                            <img
                                                src={`https://backend.cvblok.com/${cv.personalData.photo}`}  // Ensure correct photo path
                                                alt="CV Preview"
                                                className="cv-preview-img"
                                            />
                                        ) : (
                                            <div className="cv-preview-img-placeholder">Kein Bild</div>
                                        )}
                                    </div>

                                    <div className="cv-details">
                                        <h3 className="cv-name">
                                            {cv.personalData.firstName} {cv.personalData.lastName}
                                        </h3>
                                        <p className="cv-slogan">{cv.personalData.slogan}</p>
                                        <p className="cv-last-updated">
                                            <strong>Zuletzt aktualisiert:</strong> {formatDate(cv.lastUpdated)}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {/* Edit Button with Icon */}
                            <Link to={`/editor/${cv.id}`} className="edit-link">
                                <FontAwesomeIcon icon={faEdit} style={{marginRight: 5}}/>
                                <span className="small-text">Bearbeiten</span>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

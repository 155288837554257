import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import './ResumeEditor.css';
import logo from '../logo.png';

const ResumeEditor = () => {
    const [resumeData, setResumeData] = useState({
        personalData: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            postalCode: '',
            city: '',
            slogan: '',
            customFields: [],
        },
        photo: null,
        template: 'template1'
    });
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [previewPhoto, setPreviewPhoto] = useState(null); // Separate state for the preview image

    useEffect(() => {
        const fetchCV = async () => {
            if (id) {
                try {
                    const { data } = await axios.get(`https://backend.cvblok.com/cvs/${id}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    // Check if data and personalData exist
                    if (data && data.personalData) {
                        setResumeData({
                            personalData: data.personalData,
                            photo: data.personalData.photo || null,
                            template: data.personalData.template || 'template1', // If template exists in response, set it, else default to 'template1'
                        });
                    }
                } catch (error) {
                    console.error('Error fetching CV:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                // If no CV ID is provided, set default data
                setResumeData({
                    personalData: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        address: '',
                        postalCode: '',
                        city: '',
                        slogan: '',
                        customFields: [],
                    },
                    photo: null,
                    template: 'template1',
                });
                setLoading(false);
            }
        };
        fetchCV();
    }, [id, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setResumeData((prevData) => ({
            ...prevData,
            personalData: { ...prevData.personalData, [name]: value },
            photo: prevData.photo
        }));
        setUnsavedChanges(true);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            const photoUrl = URL.createObjectURL(file); // Generate Blob URL for preview
            setPreviewPhoto(photoUrl); // Update the preview state
            setResumeData((prevData) => ({
                ...prevData,
                photo: file, // Keep the file object for uploading
            }));
            setUnsavedChanges(true); // Mark changes as unsaved
        }
    };

    const handleCustomFieldChange = (index, field, value) => {
        const updatedFields = [...resumeData.personalData.customFields];
        updatedFields[index] = { ...updatedFields[index], [field]: value };
        setResumeData((prevData) => ({
            ...prevData,
            personalData: { ...prevData.personalData, customFields: updatedFields },
        }));
        setUnsavedChanges(true);
    };

    const addCustomField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                customFields: [...prevData.personalData.customFields, { title: '', value: '' }]
            }
        }));
        setUnsavedChanges(true);
    };

    const removeCustomField = (index) => {
        const updatedFields = resumeData.personalData.customFields.filter((_, i) => i !== index);
        setResumeData({ ...resumeData, personalData: { customFields: updatedFields } });
        setUnsavedChanges(true);
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('token'); // Get token from localStorage
        if (!token) {
            alert("You must be logged in to create or update a CV");
            return;
        }

        const formData = new FormData();
        formData.append('personal_data', JSON.stringify({
            firstName: resumeData.personalData.firstName,
            lastName: resumeData.personalData.lastName,
            email: resumeData.personalData.email,
            phone: resumeData.personalData.phone,
            address: resumeData.personalData.address,
            postalCode: resumeData.personalData.postalCode,
            city: resumeData.personalData.city,
            slogan: resumeData.personalData.slogan,
            customFields: resumeData.personalData.customFields, // Send customFields here
        }));

        if (resumeData.photo) {
            formData.append('photo', resumeData.photo);
        }

        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;
        // Creating new CV
        formData.append('user_id', userId);

        try {
            if (id) {
                formData.append('cv_id', id);
                const response = await axios.post(`https://backend.cvblok.com/cvs/update`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setUnsavedChanges(false);
                console.log('CV updated successfully:', response.data);
            } else {
                const response = await axios.post('https://backend.cvblok.com/cvs/create', formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log('CV created successfully:', response.data);
                navigate(`/editor/${response.data.id}`);
            }
        } catch (error) {
            console.error('Error saving CV:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(error.response?.data?.message || 'An error occurred');
            }
        }
    };

    const handleTemplateChange = (e) => {
        setResumeData((prevData) => ({
            ...prevData,
            template: e.target.value
        }));
    };

    const handleBackToDashboard = () => {
        if (unsavedChanges) {
            const confirmLeave = window.confirm('You have unsaved changes. Do you want to leave without saving?');
            if (!confirmLeave) return;
        }
        navigate('/dashboard');
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="top-bar">
                <h1>CVBlok Editor</h1>
                <div>
                    <button className="top-bar-button"><FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />Download CV</button>
                    <button onClick={handleBackToDashboard} className="top-bar-button"><FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 5 }} />Zurück zum Dashboard</button>
                    <button onClick={handleSubmit} className="top-bar-button"><FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />Speichern</button>
                </div>
            </div>
            <div className="editor-layout">
                <div className="editor-form">
                    <h2>{id ? 'Lebenslauf bearbeiten' : 'Neuen Lebenslauf erstellen'}</h2>

                    <div>
                        <label>Foto hochladen:</label>
                        <input type="file" onChange={handleFileChange} />
                    </div>

                    {/* Standard Fields */}
                    <input type="text" name="firstName" value={resumeData.personalData.firstName} onChange={handleChange} placeholder="Vorname" />
                    <input type="text" name="lastName" value={resumeData.personalData.lastName} onChange={handleChange} placeholder="Nachname" />
                    <input type="text" name="slogan" value={resumeData.personalData.slogan} onChange={handleChange} placeholder="Slogan" />
                    <input type="email" name="email" value={resumeData.personalData.email} onChange={handleChange} placeholder="E-Mail-Adresse" />
                    <input type="tel" name="phone" value={resumeData.personalData.phone} onChange={handleChange} placeholder="Telefonnummer" />
                    <input type="text" name="address" value={resumeData.personalData.address} onChange={handleChange} placeholder="Adresse" />
                    <input type="text" name="postalCode" value={resumeData.personalData.postalCode} onChange={handleChange} placeholder="Postleitzahl" />
                    <input type="text" name="city" value={resumeData.personalData.city} onChange={handleChange} placeholder="Ort" />

                    {/* Custom Fields */}
                    <div className="custom-fields">
                        {resumeData.personalData.customFields.map((field, index) => (
                            <div key={index} className="custom-field">
                                <input type="text" placeholder="Titel" value={field.title} onChange={(e) => handleCustomFieldChange(index, 'title', e.target.value)} />
                                <input type="text" placeholder="Wert" value={field.value} onChange={(e) => handleCustomFieldChange(index, 'value', e.target.value)} />
                                <button onClick={() => removeCustomField(index)}>Entfernen</button>
                            </div>
                        ))}
                        <button onClick={addCustomField}>+ Benutzerdefiniertes Feld</button>
                    </div>
                </div>

                <div className="resume-preview">
                    <div className="preview-sidebar">
                        <div className="personal-data">
                            <img
                                src={
                                    previewPhoto || (resumeData.photo && typeof resumeData.photo === 'string' ? `https://backend.cvblok.com/${resumeData.photo}` : logo)
                                }
                                alt="Profile"
                                style={{ width: '100px', height: '100px' }}
                            />

                            <p><strong>Vorname:</strong> {resumeData.personalData.firstName}</p>
                            <p><strong>Nachname:</strong> {resumeData.personalData.lastName}</p>
                            <p><strong>Slogan:</strong> {resumeData.personalData.slogan}</p>
                            <p><strong>E-Mail:</strong> {resumeData.personalData.email}</p>
                            <p><strong>Telefonnummer:</strong> {resumeData.personalData.phone}</p>
                            <p><strong>Adresse:</strong> {resumeData.personalData.address}</p>
                            <p><strong>Postleitzahl:</strong> {resumeData.personalData.postalCode}</p>
                            <p><strong>Ort:</strong> {resumeData.personalData.city}</p>

                            {/* Custom Fields Preview */}
                            {resumeData.personalData.customFields.map((field, index) => (
                                <div key={index} className="custom-field-preview">
                                    <strong>{field.title}: </strong>{field.value}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="preview-content">
                        <h3>Vorschau</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumeEditor;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ActivationPage.css';  // Assuming the CSS file for styles
import logo from '../logo.png';

const ActivationPage = () => {
    const { activationToken } = useParams();
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const response = await axios.get(`/auth/activate/${activationToken}`);
                setMessage(response.data.message);
            } catch (error) {
                setMessage(error.response?.data?.message || 'Error activating account');
            }
        };

        activateAccount();
    }, [activationToken]);

    return (
        <div className="activation-container">
            <div className="activation-content">
                <img src={logo} alt="CVBlok Logo" className="activation-logo"/>
                <h2>{message}</h2>
                <button onClick={() => navigate('/')} className="activation-btn">Go to Login</button>
            </div>
        </div>
    );
};

export default ActivationPage;

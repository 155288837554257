// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    if (!token) {
        // If there's no token, redirect to the landing page
        return <Navigate to="/" replace />;
    }
    // Otherwise, render the protected component
    return children;
};

export default ProtectedRoute;

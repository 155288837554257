import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LandingPage.css';
import logo from '../logo.png';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

const LandingPage = () => {
    const [activeForm, setActiveForm] = useState('login');
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [showModal, setShowModal] = useState(false);
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        birthDate: ''
    });
    const [language, setLanguage] = useState('tr'); // Default language is Turkish
    const [passwordPrompt, setPasswordPrompt] = useState(true); // Track if the password prompt should show
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const switchForm = (form) => setActiveForm(form);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleUserDetailsChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({ ...userDetails, [name]: value });
    };

    const setAuthToken = (token) => {
        if (token) {
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers['Authorization'];  // If token is not present, remove it
        }
    };

    // Use this function after login
    const handleSubmit = async () => {
        try {
            if (activeForm === 'register') {
                // Register logic
                const response = await axios.post('https://backend.cvblok.com/auth/register', formData);
                alert('Registration successful. Please check your email for activation.');
                setActiveForm('login');
            } else if (activeForm === 'login') {
                // Regular login logic
                const { data } = await axios.post('https://backend.cvblok.com/auth/login', formData);
                localStorage.setItem('token', data.token);

                // Set token in axios defaults
                setAuthToken(data.token);

                navigate('/dashboard');  // Navigate directly to dashboard after successful login
            } else if (activeForm === 'loginWithMNH') {
                setShowModal(true);  // Show modal only for MNH login
            }
        } catch (error) {
            console.error('Error:', error.response?.data?.message || error.message);
            alert(error.response?.data?.message || 'An error occurred');
        }
    };

    const handleModalSubmit = async () => {
        try {
            if (activeForm === 'loginWithMNH') {
                // Send the MNH ID and user details to the backend for validation
                const { data } = await axios.post('https://backend.cvblok.com/auth/login/mnh', {
                    mnhId: formData.mnhId,
                    firstName: userDetails.firstName,
                    lastName: userDetails.lastName,
                    email: userDetails.email,
                    birthDate: userDetails.birthDate,
                });

                console.log('MNH login successful:', data);

                if (data.token) {
                    localStorage.setItem('token', data.token);  // Save the token to localStorage
                    navigate('/dashboard');  // Redirect to the dashboard after successful MNH login
                }
            }
        } catch (error) {
            console.error('MNH Login Error:', error);
            if (error.response && error.response.data) {
                alert(error.response.data.message || 'Login failed, please check the details and try again.');
            } else {
                alert('An error occurred during the MNH login process.');
            }
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const changeLanguage = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang);  // Change language using i18n
    };

    // Handle the password prompt
    useEffect(() => {
        const token = localStorage.getItem('token');
        // If there's no token and the password prompt hasn't been disabled yet
        if (!token && passwordPrompt) {
            const password = prompt('Giriş Kodu');

            if (password === 'blokcv!1') {  // Replace with your correct password
                setPasswordPrompt(false);  // Hide the prompt after correct password
            } else {
                alert('Kod yanlış. CVBlok, kariyerinizi şekillendirmenize yardımcı olmak için yakında sizlerle olacak!');
                window.location.reload();  // Reload page if the password is incorrect
            }
        }
    }, [passwordPrompt]);


    return (
        <div className="landing-container">
            <div className="content">
                <div className="welcome-section">
                    <img src={logo} alt="CVBlok Logo" className="logo" />
                    <h1>{t('welcome')}</h1>
                    <p className="teaser-slogan">
                        <Trans i18nKey="teaser_slogan" components={{ br: <br /> }} />
                    </p>
                </div>
                <div className="form-container">
                    {/* Language Switcher */}
                    <div className="language-switcher">
                        <span
                            className={`language-flag ${language === 'tr' ? 'active' : ''}`}
                            onClick={() => changeLanguage('tr')}
                            role="button"
                            aria-label="Türkçe"
                        >
                            🇹🇷
                        </span>
                        <span
                            className={`language-flag ${language === 'en' ? 'active' : ''}`}
                            onClick={() => changeLanguage('en')}
                            role="button"
                            aria-label="English"
                        >
                            🇬🇧
                        </span>
                        <span
                            className={`language-flag ${language === 'de' ? 'active' : ''}`}
                            onClick={() => changeLanguage('de')}
                            role="button"
                            aria-label="Deutsch"
                        >
                            🇩🇪
                        </span>
                    </div>

                    {activeForm === 'login' ? (
                        <div className="form login-form">
                            <h2>{t('login')}</h2>
                            <input type="email" name="email" placeholder={t('email')} value={formData.email}
                                   onChange={handleChange} />
                            <input type="password" name="password" placeholder={t('password')} value={formData.password}
                                   onChange={handleChange} />
                            <button onClick={handleSubmit} className="btn">{t('login')}</button>
                            <p onClick={() => switchForm('register')} className="switch-link">{t('register_message')}</p>
                            <p onClick={() => setActiveForm('loginWithMNH')} className="switch-link">{t('mnh_login')}</p>
                        </div>
                    ) : activeForm === 'loginWithMNH' ? (
                        <div className="form mnh-login-form">
                            <h2>{t('mnh_login')}</h2>
                            <input type="text" name="mnhId" placeholder="ID" value={formData.mnhId}
                                   onChange={handleChange} />
                            <button onClick={handleSubmit} className="btn">{t('login')}</button>
                            <p onClick={() => switchForm('login')} className="switch-link">{t('normal_login')}</p>
                        </div>
                    ) : (
                        <div className="form register-form">
                            <h2>{t('register')}</h2>
                            <input type="email" name="email" placeholder={t('email')} value={formData.email}
                                   onChange={handleChange} />
                            <input type="password" name="password" placeholder={t('password')} value={formData.password}
                                   onChange={handleChange} />
                            <button onClick={handleSubmit} className="btn">{t('register')}</button>
                            <p onClick={() => switchForm('login')} className="switch-link">{t('already_registered')}</p>
                        </div>
                    )}
                </div>
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span onClick={closeModal} className="close-btn">X</span>
                        <h3>{t('enter_personal_details')}</h3>
                        <p>{t('personal_details_message')}</p>
                        <input type="text" name="firstName" placeholder={t('first_name')} value={userDetails.firstName}
                               onChange={handleUserDetailsChange} />
                        <input type="text" name="lastName" placeholder={t('last_name')} value={userDetails.lastName}
                               onChange={handleUserDetailsChange} />
                        <input type="email" name="email" placeholder={t('email')} value={userDetails.email}
                               onChange={handleUserDetailsChange} />
                        <input type="date" name="birthDate" placeholder={t('birth_date')} value={userDetails.birthDate}
                               onChange={handleUserDetailsChange} />
                        <button onClick={handleModalSubmit}>{t('save_and_login')}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LandingPage;
